<template>
<transition 
  enter-active-class="animate__animated animate__fadeInDown"
  leave-active-class="animate__animated animate__fadeOutUp"
  mode="out-in">
  <div class="modal main-modal" v-if="show">
    <div class="modal-container">
      <div class="modal-wrapper">
        <div class="swiper-modal swiper-carousel swiper-container swiper swiper-container-horizontal">
          <div class="swiper-wrapper">
            <div class="swiper-slide"><KampanyaBagis @clicked="slideToNext" :donationData="donationData" ref="progressBar" :progressBar="progressBar" /></div>
            <div class="swiper-slide"><Bilgiler :campaignId="campaignId" @clicked="slideToNext" :formValue="formValue" :donationType="campaignDonate"/></div>
            <div class="swiper-slide"><Odeme :donationType="campaignDonate" @clicked="slideToNext"/></div>
            <div class="swiper-slide"><FormSuccess successType="campaignDonate" :campaignData="donationData"  @clicked="closeModal;destroyCarousel"/></div>
          </div>
          
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
        <FrameFooter :backNav="backNav" @clicked="prevSlide; destroyModal"/>
      </div>

    </div>
  </div>
</transition>
</template>

<script>
import { Swiper, Scrollbar, EffectCoverflow, SwiperClass, Pagination, Navigation, Mousewheel, Autoplay, EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar, EffectCoverflow, Mousewheel, Autoplay, EffectFade]);
import 'swiper/swiper-bundle.css';

import KampanyaBagis from "@/components/KampanyaBagis";
import Odeme from "@/components/Odeme";
import Bilgiler from "@/components/Bilgiler";
import FormSuccess from "@/components/FormSuccess";
import FrameFooter from "@/components/FrameFooter";

export default {
  name: "ModalKampanyaOlustur",
  components: {
    KampanyaBagis, Bilgiler, Odeme, FormSuccess, FrameFooter
  },
  props: ["campaignId"],
  data() {
    return {
      donationData: {},
      campaignDonate: "campaignDonate",
      formValue: {},
      backNav: ["Ana Sayfa", "/"],
      formData: '',
      show: false,
      swiperModal: '',
      progressBar: '',
      campaignDetailData : {}
    };
  },
  mounted() {
    // Listen ESC for close
    window.addEventListener('keydown', (e)=>{
      var key = e.which || e.keyCode;
      if (key === 27) {
        this.closeModal();
      }
    });
    this.getCampaignDetail();
  },
  
  methods:{
    getSwiper(){
      setTimeout(() =>{
        this.swiperModal = new Swiper('.swiper-modal', {
          pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
          },
          loop: false,
          autoHeight: false,
          slideToClickedSlide:true,
          paginationClickable: false,
          mousewheelControl: 1,
          parallax: false,
          speed: 0,
          slidesPerView: 1,
          effect: "fade",
          fadeEffect: { crossFade: true },
          grabCursor: false,
          allowTouchMove: false,
          simulateTouch: false,
          on: {
            init: () => this.onSlideChange(),
          }
        });
      }, 100);
    },
    onSlideChange(){
      this.progressBar = 79;
      this.$refs.progressBar.progressBar(this.progressBar);
    },
    getCampaignDetail(){
      let params = {};
      params["slug"] = this.$route.params.slug;
      this.$api.getCampaign(params).then(response => {
        this.donationData = response;
      });
    },
    footerLink(data){
      this.backNav = data;
    },
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    slideToNext (value) {
      this.swiperModal.slideNext();
      
      if (this.swiperModal.activeIndex  == 0){
        this.backNav = ["Ana Sayfa", ""]
      }else if (this.swiperModal.activeIndex  == 1){
        this.backNav = ["Bağış Yap", ""]
      }else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Bilgileriniz", ""]
      }
    },
    prevSlide(){
      if(this.swiperModal.activeIndex > 0 ){
        this.swiperModal.slidePrev();
      }else{
        this.closeModal();
      }

      if (this.swiperModal.activeIndex  == 0){
        this.backNav = ["Ana Sayfa", ""]
      }else if (this.swiperModal.activeIndex  == 1){
        this.backNav = ["Bağış Yap", ""]
      }else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Bilgileriniz", ""]
      }
    },
    destroyCarousel(){
      this.swiperModal = '';
    },
  }
};
</script>