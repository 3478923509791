<template>
  <div>
    <loading
      :active.sync="isLoading"
      background-color="#332abb"
      :opacity="0.99"
      color="#fff"
      :is-full-page="fullPage"
    ></loading>

    <ModalKampanyaBagis v-if="detailData.detail" :campaignId="detailData.detail.id" ref="modalKampanyaBagis"
    ></ModalKampanyaBagis>
    <modalVideo ref="modalVideo" :videoUrl="newData"></modalVideo>
    <donorsModal
      ref="donorsModal"
      :data="detailData.donation_users"
    ></donorsModal>

    <div class="kampanya-detay">
      <div class="container" v-if="detailData.detail">
        <section
          class="section-padding container d-flex flex-column flex-md-row"
        >
          <div class="kampanya-detay-card d-flex flex-column flex-1">
            <div class="head d-flex align-items-center">
              <!-- <img src="https://via.placeholder.com/100" class="rounded-circle mr-3"> -->
              <h2 v-html="detailData.detail.title + (detailData.detail.city_name ? ' - ' + detailData.detail.city_name : '') "></h2>
            </div>

            <div class="kampanya-detay-info">
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="font-bold" v-if="!detailData.total_donation_show">
                  {{ detailData.total.donations_collected }}
                </h3>
                <div class="text-right ml-auto" v-if="detailData.detail.price > 0">
                  <p class="font-bold">{{ detailData.detail.full_price }}</p>
                  <span>Planlanan Bağış</span>
                </div>
              </div>

              <ProgressBar
                v-if="detailData.detail.price > 0"
                :value="detailData.total.percent"
                bgColor="blue"
                class="mt-2"
              />

              <div
                class="
                  d-flex
                  flex-column flex-md-row
                  justify-content-between
                  mt-4
                "
              >
                <UserCard
                  class="mb-4 mb-md-0"
                  fontColor="blue"
                  :image="detailData.campaign_user.image"
                  :userName="
                    detailData.campaign_user.name +
                    ' ' +
                    detailData.campaign_user.surname
                  "
                />

                <ButtonRounded
                  Text="Bağış Yap"
                  :isRouting="false"
                  @click.native="modalKampanyaBagis"
                  :disabled="
                    (detailData.total.percent >= 100 ||
                      detailData.detail.complete_status == 'completed') &&
                    true
                  "
                />
              </div>
            </div>

            <div
              class="
                social-button
                w-100
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p class="">Kampanyayı Paylaş</p>
              <div class="">
                <a @click="twShare" href="" target="_blank"><span class="svg-icon icons-twitter"></span></a>
                <a @click="fbShare" href="" target="_blank"><span class="svg-icon icons-facebook"></span></a>
                <a @click="linkedinShare" href="" target="_blank"><span class="svg-icon icons-linkedin"></span></a>
                <a @click="waShare" href="" target="_blank"><span class="svg-icon icons-whatsapp"></span></a>
              </div>
            </div>

            <div class="kampanya-detay-bottom my-auto">
              <p v-html="detailData.detail.message"></p>
              <span
                v-if="detailData.detail.video"
                @click="showModalVideo(detailData.detail.video)"
              >
                <div class="play-icon d-flex align-items-center mt-3">
                  <div class="youtube-svg"></div>
                  <span class="pl-1 text-underline">Videoyu İzle</span>
                </div>
              </span>
            </div>
            
            <div class="kampanya-list-info" 
            v-if="!detailData.donation_list_show && Object.keys(this.detailData.donation_users).length > 0">
              <button
                class="w-100 collapsed text-left d-flex justify-content-between"
                type="button"
                data-toggle="collapse"
                data-target="#bagisyapanlar"
                aria-expanded="false"
                aria-controls="bagisyapanlar"
              >
                Bağış Yapanlar
                <span class="svg-icon icons-arrow-down"></span>
              </button>
              
              <div class="collapse" id="bagisyapanlar">
                <div class="list-detail mt-3">
                  <ul>
                    <li
                      v-for="(users, index) in topDonators"
                      :key="index"
                    >
                      <div class="d-flex justify-content-between">
                        <p>{{ users.name_surname }}</p>
                        <p>{{ users.price }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <span
              class="show-all"
              @click="showDonorsModal"
             v-if="topDonators.length > 9"
              >Tümünü Gör</span
            >
          </div>

          <div
            class="
              flex-1
              d-flex
              flex-column
              align-items-center
              justify-content-center
              mt-3 mt-md-0
            "
          >
            <img src="/svg/login.svg" class="left-image d-none d-md-block" />
          </div>
        </section>
      </div>

      <div class="spacer clearfix"></div>
    </div>

    <FooterLanding />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import FooterLanding from "@/components/FooterLanding";
import ProgressBar from "@/components/ProgressBar";
import UserCard from "@/components/UserCard";
import ButtonRounded from "@/components/ButtonRounded";
import ModalKampanyaBagis from "@/components/ModalKampanyaBagis";
import ModalVideo from "@/components/ModalVideo";
import donorsModal from "@/components/donorsModal";

export default {
  name: "KampanyaDetay",
  components: {
    Loading,
    FooterLanding,
    ProgressBar,
    UserCard,
    ButtonRounded,
    ModalKampanyaBagis,
    ModalVideo,
    donorsModal,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      detailData: {},
      newData: {},
      metaData: {},
      first10Donators: [],
      topDonators:[]
    };
  },
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: this.metaData.title },
        { property: "og:description", content: this.metaData.description },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: this.metaData.title },
        { name: "twitter:description", content: this.metaData.description },
        { name: "twitter:url", content: window.location.href },
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted() {
    this._getCampaignDetail();
  },
  methods: {
    twShare() {
      event.preventDefault();
      // window.open("https://twitter.com/share?url=" + this.metaData.title + ' ' + window.location.href);
      window.open(
        "https://twitter.com/share?text=" +
          this.metaData.title +
          "&url=" +
          window.location.href
      );
    },
    fbShare() {
      event.preventDefault();
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
          window.location.href +
          "&quote=" +
          this.metaData.title
      );
    },
    linkedinShare() {
      event.preventDefault();
      // window.open("https://www.linkedin.com/shareArticle?mini=true&url="+window.location.href+"&title=Bilim BilimSeferberliği&summary=" + this.metaData.title);
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
          window.location.href
      );
    },
    waShare() {
      event.preventDefault();
      window.open(
        "https://api.whatsapp.com/send?text=" +
        this.metaData.title +
        " " +
        window.location.href
      );
    },
    showDonorsModal() {
      this.$refs.donorsModal.openModal();
    },
    showModalVideo(data) {
      this.$refs.modalVideo.openModal(data);
      this.newData = data;
    },
    modalKampanyaBagis() {
      this.$refs.modalKampanyaBagis.openModal();
      this.$refs.modalKampanyaBagis.getSwiper();
    },
    _getCampaignDetail() {
      let params = {};
      params["slug"] = this.$route.params.slug;
      this.$api.getCampaign(params).then((response) => {
        this.detailData = response;
        
        const Donors = JSON.stringify(this.detailData.donation_users);
        this.first10Donators = JSON.parse(Donors);
        this.topDonators = this.first10Donators.slice(0,10);

         
        this.metaData = {
          title: this.detailData.detail.title,
          description: this.detailData.detail.message,
          slug: this.detailData.detail.slug,
        };
        
      });
    },
  },
};
</script>

<style lang="scss">
.kampanya-detay {
  background: var(--blueOcean);
  padding-top: 10rem;
  .kampanya-detay-card {
    background: #fff;
    border-radius: 10px;
    color: var(--purpleDark);

    .head {
      padding: 2rem;
      h2 {
        font-size: 1.6rem;
        line-height: 1.5;
      }
    }
  }
  .social-button {
    padding: 2rem;
    background: #f5f8fe;
    a {
      .svg-icon {
        width: 1.8rem;
        height: 1.8rem;
        margin-left: 0.8rem;
        background: var(--purpleLight);
      }
    }
  }
  .kampanya-detay-info {
    padding: 2rem;
    background: var(--whiteOld);
  }
  .kampanya-list-info {
    padding: 2rem;
    background: var(--whiteOld);
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    button {
      width: 100%;
      font-size: 1rem;
      font-weight: 500;
      color: var(--purpleDark);
      background: transparent;
      &:focus {
        outline: none !important;
      }
      .svg-icon {
        background: var(--purpleDark);
        width: 1.5rem;
        height: 1.5rem;
        transition: 0.5s ease;
        transform: rotate(-180deg);
      }
    }
    .collapsed {
      .svg-icon {
        transform: rotate(0deg);
      }
    }
    .list-detail {
      ul {
        li {
          list-style-type: none;
          padding-left: 0px;
          p {
            color: var(--purpleDarkLight);
            font-size: 1rem;
          }
        }
      }
    }
  }
  .show-all {
    background: var(--purpleDark);
    color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 1.5rem;
    text-align: center;
    cursor: pointer;
  }

  .kampanya-detay-bottom {
    padding: 2rem;
  }
  .youtube-svg {
    background-color: var(--purpleDark);
    -webkit-mask-image: url(/svg/icon-video.svg);
    mask-image: url(/svg/icon-video.svg);
    -webkit-mask-size: contain;
    background-size: cover;
    width: 24px;
    height: 24px;
    fill: var(--purpleDark);
  }
  .play-icon {
    cursor: pointer;
  }
  .left-image {
    min-width: 50%;
  }
}
</style>